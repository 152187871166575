import ChatBot from 'react-simple-chatbot';
import React from 'react';
import Button from '@material-ui/core/Button';
import {
  Link,
} from "next/link";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { routingActions } from '../redux/_actions';
import { ThemeProvider } from 'styled-components';
import Router from 'next/router';

const theme =  {
        background: '#fff',
        fontFamily: 'Poppins',
        headerBgColor: 'rgb(30, 50, 85)',
        headerFontColor: '#fff',
        headerFontSize: '15px',
        botBubbleColor: 'rgb(30, 50, 85)',
        botFontColor: '#fff',
        userBubbleColor: '#fff',
        userFontColor: '#4a4a4a',
    }


const styles = theme => ({
  chatbotButton: {
    color: 'white'
  },
  chatbotButton1: {
    color: 'white',
    margin: '5px'
  },
  chatbotButton2: {
    color: 'white',
    margin: '5px 0px',
    textAlign: 'left'
  },
  chatbotLink: {
    textDecoration: 'none'
  },

});

  class Chatbot extends React.Component{
    state = {
      opened: false,
      chatbotKey: Math.random()
    }

    toggleFloating = ({ opened }) => {
      this.setState({ opened }); 
    }
    

    handleClear = (route) => {
      this.setState({ chatbotKey: Math.random(), opened: false });
      Router.push(route);
    }

    render(){
      const {classes} = this.props;
      const steps = [
        {
          id: '1',
          message: 'Hey! How can we help you?',
          trigger: '2',
        },
        {
          id: '2',
          options: [
            { value: 1, label: 'I want to rent out my place', trigger: '3' },
            { value: 2, label: 'I am looking for a place to rent', trigger: '4' },
            { value: 3, label: 'Other', trigger: '5' },
          ],
        },
        {
          id: '3',
          component: (
            <div className={classes.buttonsDiv}>
              <Button variant="contained" color="secondary" className={classes.chatbotButton2} size="small" onClick={() => this.handleClear('/post/rent')}>I want to rent out an entire house</Button><br/>
              <Button variant="contained" color="secondary" className={classes.chatbotButton2} size="small" onClick={() => this.handleClear('/post/rent')}>I want to rent out a room</Button><br/>
              <Button variant="contained" color="secondary" className={classes.chatbotButton2} size="small" onClick={() => this.handleClear('/post/rent')}>I am looking for a roommate in my apartment</Button><br/>
              <Button variant="contained" color="secondary" className={classes.chatbotButton2} size="small" onClick={() => this.handleClear('/post/rent')}>I want to rent PG/Co-living spaces</Button>
            </div>
          ),
          end: true,
        },
        {
          id: '4',
          component: (
            <div className={classes.buttonsDiv}>
              <Button variant="contained" color="secondary" className={classes.chatbotButton} size="small" onClick={() => this.handleClear('/post/requirement')}>Post a requirement</Button><br /><br/>
              <p style={{margin: '5px'}}>OR <br/><br/>Search by city: </p><br/>
              <Button variant="contained" color="secondary" className={classes.chatbotButton1} size="small" onClick={() => this.handleClear('/properties/bangalore')}>Bangalore</Button>
              <Button variant="contained" color="secondary" className={classes.chatbotButton1} size="small" onClick={() => this.handleClear('/properties/pune')}>Pune</Button>
              <Button variant="contained" color="secondary" className={classes.chatbotButton1} size="small" onClick={() => this.handleClear('/properties/hyderabad')}>Hyderabad</Button>
              <Button variant="contained" color="secondary" className={classes.chatbotButton1} size="small" onClick={() => this.handleClear('/properties/mumbai')}>Mumbai</Button>
            </div>
          ),
          end: true,
        },
        {
          id: '5',
          component: (
            <div className={classes.buttonsDiv}>
              <Button variant="contained" color="secondary" className={classes.chatbotButton2} size="small" onClick={() => this.handleClear('/contact')}>Contact Us</Button><br/>
            </div>
          ),
          end: true,
        },
        {
          id: '6',
          user: true,
          trigger: '7',
        },
        {
          id: '7',
          message: 'Thanks for your messaege. We will get back to you:)',
          end: true,
        },
      ];
  return (
    <ThemeProvider theme={theme}>
    <ChatBot
      opened={this.state.opened}
      steps={steps}
      floating={true}
      toggleFloating={this.toggleFloating}
      key={this.state.chatbotKey}
    />
    </ThemeProvider>
  )
}
}

Chatbot.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { currentPage, onChangeListeners } = state.routing;
  return {
    currentPage,
    onChangeListeners
  };
}


const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (currentPage) => {
    dispatch(routingActions.setCurrentPage(currentPage))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Chatbot));